import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ContentWrapper from "../components/ContentWrapper";
import { Link } from "gatsby";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
  .breadcrumbs {
    background: #ececec;
    padding: 19px 0;
    min-height: 47px;
  }
  .breadcrumbs-list {
    font-size: 14px;
    line-height: 1;
  }
  .breadcrumbs-item {
    float: left;
    margin: -2px 6px 0 0;
    &:last-child {
      &::before {
        content: ">";
        padding: 0 5px 0 0;
      }
    }
  }
  .breadcrumbs-item-link {
    color: #555;
    &:hover {
      text-decoration: underline;
    }
  }
  form {
    text-align: center;
  }
  .form-group {
    display: flex;
    align-items: center;
    margin: 15px 0;
    justify-content: center;
    &:first-of-type {
      margin: 30px 0 15px;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        margin: 15px 0;
      }
    }
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      flex-direction: column;
      align-items: normal;
    }
  }
  .form-label {
    font-size: 1rem;
    color: #000;
    width: 32%;
    text-align: left;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      width: auto;
      margin-bottom: 3px;
    }
  }
  .form-input {
    width: 100%;
    background: #eee;
    padding: 10px;
    border-radius: 2px;
    font-size: 0.875rem;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1rem;
    }
  }
  .form-group-textarea {
    display: flex;
    align-items: flex-start;
    margin: 15px 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      flex-direction: column;
      align-items: normal;
    }
  }
  .form-textarea {
    width: 100%;
    height: 300px;
    background: #eee;
    padding: 10px;
    border-radius: 2px;
    font-size: 0.875rem;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1rem;
    }
  }
  .form-button {
    padding: 10px 20px;
    background: #5630af;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 2px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
      top: 1px;
      background: rgba(86, 48, 175, 0.8);
    }
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      width: 100%;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
`;

const Contact = () => {
  return (
    <Layout>
      <Seo title="お問い合わせ" description="株式会社八木造園土木のお問い合わせページです。" />
      <MainWrapper>
        <div className="breadcrumbs">
          <ContentWrapper>
            <ol className="breadcrumbs-list">
              <li className="breadcrumbs-item">
                <Link to={`/`} className="breadcrumbs-item-link">ホーム</Link>
              </li>
              <li className="breadcrumbs-item">
                <Link to={`/contact`} className="breadcrumbs-item-link">お問い合わせ</Link>
              </li>
            </ol>
          </ContentWrapper>
        </div>
        <ContentWrapper>
          <form name="contact" method="POST" action="/thankyou" data-netlify="true" data-netlify-honeypot="HelloHoney">
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" id="subject" name="subject" value="お問い合わせ通知"></input>
            <input type="hidden" name="HelloHoney" />
            <div className="form-group">
              <label htmlFor="お名前" className="form-label">お名前</label>
              <input type="text" name="お名前" size="40" autoComplete="name" className="form-input" placeholder="お名前" required />
            </div>
            <div className="form-group">
              <label htmlFor="メールアドレス" className="form-label">メールアドレス</label>
              <input type="email" name="メールアドレス" size="40" autoComplete="email" pattern="([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}" className="form-input" placeholder="メールアドレス" required />
            </div>
            <div className="form-group">
              <label htmlFor="件名" className="form-label">件名</label>
              <input type="text" name="件名" size="40" className="form-input" placeholder="件名" />
            </div>
            <div className="form-group-textarea">
              <label htmlFor="お問い合わせ内容" className="form-label">お問い合わせ内容</label>
              <textarea name="お問い合わせ内容" className="form-textarea" placeholder="お問い合わせ内容" required></textarea>
            </div>
            <button type="submit" className="form-button">送信</button>
          </form>
        </ContentWrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Contact;